export const skyToWhite = `
#b2f2ff,
#ccf3ff,
#e4f5ff,
#f5f9ff,
#ffffff
`;

export const darkBlueToWhite = `#0c1b3d, #394e6b, #6f869a, #aec0ca, #f6fdff`;

export const whiteToTransparent = `
rgba(255, 255, 255, 1) 0%,
rgba(255, 255, 255, 0.95) 10%,
rgba(255, 255, 255, 0.85) 15%,
rgba(255, 255, 255, 0.738) 19%,
rgba(255, 255, 255, 0.541) 34%,
rgba(255, 255, 255, 0.382) 47%,
rgba(255, 255, 255, 0.278) 56%,
rgba(255, 255, 255, 0.194) 65%,
rgba(255, 255, 255, 0.126) 73%,
rgba(255, 255, 255, 0.075) 80.2%,
rgba(255, 255, 255, 0.042) 86.1%,
rgba(255, 255, 255, 0.021) 91%,
rgba(255, 255, 255, 0.008) 95.2%,
rgba(255, 255, 255, 0.002) 98.2%,
rgba(255, 255, 255, 0) 100%
`;

export const blueFade = `
rgba(10,15,25,0) 5%,
rgba(5,12,55,0.780) 25%,
rgba(5,12,55,0.917) 35%,
rgba(5,12,55,0.959) 40%,
rgba(5,12,55,1) 50%,
rgba(5,12,55,0.962) 60%,
rgba(5,12,55,0.937) 65%,
rgba(5,12,55,0.895) 70%,
rgba(5,12,55,0.615) 80%,
rgba(255,255,255,0) 95%
`;

export const blueToWhite = `#06666f, #1f727b, #307f87, #3f8b93, #4d98a0, #62a4b0, #78b0c0, #8dbcce, #afcbde, #cfdbea, #eaecf4, #ffffff`;

export const blackToBlue = `#121212, #121019, #110e20, #0f0b26, #0b092d, #080933, #040839, #00083f, #000946, #000a4c, #000953, #000859`;

export const textRedPurple = `
background: linear-gradient(to right, #ff0091, #ec47b7, #d269d4, #b582e5, #9a95ec, #81a5f7, #68b3fd, #54c0fd, #00d2ff, #00e3ff, #00f2f3, #00ffde);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
`;

export const textGrayBlue = `
background-image: linear-gradient(to right top, #2afff6, #00e7ff, #00ccff, #00afff, #008dff, #3d78ff, #6d5bfb, #972bea, #7433d8, #5235c3, #3033ad, #022f96);
`;

export const textBlueOrange = `
background-image: radial-gradient(circle, #00fff4, #00ebff, #00d5ff, #00bdff, #00a3ff, #2a97ff, #4d8aff, #6e7aff, #9479ff, #b677ff, #d674ff, #f370ff)`;
