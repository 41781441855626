import React from "react";
import styled from "styled-components";
import { flexbox } from "../../../style/mixins";
import {
  subsAppear,
  subsAniController
} from "../../../style/animations/subsAnimations";
import { FaLink, FaGithub, FaLinkedin } from "react-icons/fa";
import * as c from "../../../style/colors";

const ProjectItem = ({
  sectionShowed,
  delayOffset,
  PrjName,
  PrjDescription,
  PrjPicture,
  PrjSite,
  PrjRepo,
  PrjLinkedin
}) => {
  return (
    <ProjectItemBox sectionShowed={sectionShowed} delayOffset={delayOffset}>
      <Container>
        <ProjectDetails>
          <Name href={PrjSite} target="_blank">
            {PrjName}
          </Name>
          <Description>{PrjDescription}</Description>
        </ProjectDetails>
        <ProjectLinks>
          <Picture image={PrjPicture} href={PrjSite} target="_blank" />
          <Link>
            <LinkBox href={PrjSite} target="_blank">
              <FaLink />
            </LinkBox>
            <LinkBox href={PrjRepo} target="_blank">
              <FaGithub />
            </LinkBox>
            <LinkBox href={PrjLinkedin} target="_blank">
              <FaLinkedin />
            </LinkBox>
          </Link>
        </ProjectLinks>
      </Container>
    </ProjectItemBox>
  );
};

export default ProjectItem;

const ProjectItemBox = styled.div`
  ${flexbox()};
  flex-basis: 44%;
  height: 40%;

  scroll-snap-stop: always;
  scroll-snap-align: center;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  color: white;
  padding: 10px;
  box-shadow: 1px 1px 2px #050c37;
  margin-right: 35px;
  margin-bottom: 35px;


  opacity: 0;

  animation: ${({ sectionShowed, delayOffset }) =>
    sectionShowed && subsAniController(subsAppear(0), 1, delayOffset)};

  @media (max-width: 1080px) {
    flex-basis: 40%;
    margin-right: 20px;
  }
  @media (max-width: 768px) {
    flex-basis: 100%;
    height: 80%;
    min-width: 70%;
    margin: 10px;
  }
`;

const Container = styled.div`
  overflow: hidden;
  margin: auto;
  ${flexbox({ dir: "row", fw: "nowrap", jc: "space-between" })};
  position: relative;
  height: 100%;
  width: 100%;

  @media (max-width: 768px) {
    flex-basis: 100%;
    flex-direction: column;
    min-width: 90%;
  }
`;

const ProjectDetails = styled.div`
  height: 100%;
  flex-basis: 59%;

  ${flexbox({ dir: "column" })};

  @media (max-width: 768px) {
    order: 2;
    width: 100%;
    flex-basis: 54%;
  }
`;

const Name = styled.a`
  text-decoration: none;
  flex-basis: 30%;
  width: 100%;
  height: 100%;
  ${flexbox()};
  font-size: 2.2rem;
  color: white;
  opacity: 0.9;
  box-sizing: border-box;
  text-shadow: 1px 1px 2px black;
  font-family: "Montserrat";
  transition: 0.3s ease-out;

  &:hover {
    opacity: 1;
    text-shadow: 0px 0px 5px white;
  }

  @media (max-width: 1440px) {
    font-size: 1.5rem;
  }
  @media (max-width: 1080px) {
    font-size: 1.2rem;
  }
  @media (max-width: 768px) {
    font-size: 1.2rem;
    height: 15%;
  }
`;

const Description = styled.div`
  flex-basis: 30%;
  ${flexbox()};
  width: 100%;
  height: 100%;
  flex-basis: 70%;
  line-height: 1.4rem;
  font-size: 1.2rem;
  overflow-y: auto;
  overflow-x: hidden;
  text-shadow: 1px 1px 2px black;
  font-family: "Ubuntu";
  font-weight: 300;

  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background: dimgrey;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px lightgray;
    border-radius: 5px;
  }

  @media (max-width: 1440px) {
    line-height: 1.2rem;
    font-size: 1.1rem;
  }

  @media (max-width: 1080px) {
    font-size: 0.8rem;
    line-height: 1rem;
  }
  @media (max-width: 768px) {
    align-items: flex-start;
    width: 80%;
    font-size: 0.9rem;
    line-height: 1.1rem;
  }
`;

const ProjectLinks = styled(ProjectDetails)`
  flex-basis: 39%;

  @media (max-width: 768px) {
    order: 1;
    width: 100%;
    flex-basis: 49%;
  }
`;

const Picture = styled.a`
  flex-basis: 70%;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: center / cover no-repeat url(${({ image }) => image});
  transition: 0.3s ease-out;

  &:hover {
    box-shadow: inset 0px 0px 15px white, 0px 0px 10px white;
  }

  @media (max-width: 768px) {
    order: 1;
    margin: 30px 0 20px 0;
    width: 90%;
    flex-basis: 90%;
    height: 90%;
  }
`;

const Link = styled.div`
  ${flexbox()};
  width: 100%;
  height: 100%;
  flex-basis: 30%;
  font-size: 0.1rem;

  @media (max-width: 768px) {
    order: 2;
    flex-basis: 10%;
    height: 10%;
  }
`;

const LinkBox = styled.a`
  ${flexbox()};
  flex-basis: 20%;
  font-size: 2.2rem;
  color: ${c.blackText};
  width: 100%;
  height: 100%;
  opacity: 0.6;
  margin: 0 5px;

  transition: 0.2s ease-out;
  &:hover {
    opacity: 1;
    text-shadow: 0px 0px 5px white;
  }

  @media (max-width: 1440px) {
    font-size: 2rem;
  }

  @media (max-width: 1080px) {
    font-size: 1.6rem;
  }
`;
