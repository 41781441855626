import { css, keyframes } from "styled-components";
import * as c from "../colors"

export const headerAniController = (anim, length = 1, delay = 1) =>
  css`
    ${anim} ${length}s ${delay}s ease-in-out forwards
`;

export const hiAppear = keyframes`
0%{
    color: transparent;
    text-shadow: 0px 0px 5px #000;
    transform: translate(350%, 350%) scale(10);
    opacity: 0;
}
20%{
    color: transparent;
    text-shadow: 0px 0px 5px #000;
}
60%{
    transform: translate(340%, 340%) scale(9);
    opacity: 1;    
    color: white
    text-shadow: none;
}
100%{
    transform: translate(0, 0) scale(1);
    opacity: 1;
    color: white
    text-shadow: none;
}
`;

export const dotAppear = keyframes`
0%{
    transform: translateY(-85%) scale(3);
    opacity: 0;
    color: transparent;
    text-shadow: 0px 0px 15px #000;
}
100%{
    transform: translateY(0) scale(1);
    opacity: 1;
    color: ${c.desireRed};
    text-shadow: none;
}
`;

export const myNameAppear = keyframes`
0%{
    transform: translateY(10%) scale(1.1);
    opacity: 0;
    color: transparent;
    text-shadow: 0px 0px 5px #000;
}
100%{
    transform: translateY(0) scale(1);
    opacity: 1;
    color: white
    text-shadow: none;
}
`;
