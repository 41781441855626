import React from "react";
import styled from "styled-components";
import {flexbox} from "../../../style/mixins"
import {
  headerAniController,
  hiAppear,
  dotAppear,
  myNameAppear
} from "../../../style/animations/headerAnimations";
const Header = ({sectionShowed}) => {
  return (
      <HeaderBox sectionShowed={sectionShowed}>
        <Hi sectionShowed={sectionShowed}>Hi</Hi>
        <Dot sectionShowed={sectionShowed}>.</Dot>
        <MyName sectionShowed={sectionShowed}>My name is Yarden</MyName>
      </HeaderBox>
  );
};

export default Header;

const HeaderBox = styled.div`
  ${flexbox({ ai: "flex-end" })};
  flex-basis: 100%;
  transform: translateY(-20%);
  font-size: 3rem;
  width: 100%;
  height: 20%;
  position: relative;

  @media (max-width: 1440px) {
    font-size: 2rem;
  }
  @media (max-width: 1024px) {
    width: 100%;
    transform: translateY(-40%);
  }
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

// spans need 'display' property in order to be animated
const Hi = styled.span`
  display: inline;
  text-shadow: 1px 1px 2px black;
  opacity: 0;
  animation: ${({ sectionShowed }) =>
    sectionShowed && headerAniController(hiAppear, 3, 0)};
`;

const Dot = styled.span`
  display: inline;
  text-shadow: 1px 1px 2px black;
  opacity: 0;
  animation: ${({ sectionShowed }) =>
    sectionShowed && headerAniController(dotAppear, 1, 3)};
`;

const MyName = styled.span`
  display: inline;
  text-shadow: 1px 1px 2px black;
  padding-left: 1.5%;
  opacity: 0;
  animation: ${({ sectionShowed }) =>
    sectionShowed && headerAniController(myNameAppear, 1, 4)};
`;
