import React from "react";
import styled, { keyframes } from "styled-components";
import { ReactComponent as AstroLoadSVG } from "../../src/assets/SVG/astronautLiftOff.svg";
import { flexbox } from "../style/mixins";

const LoadingScreen = () => {
  return (
    <Wrapper>
      <AstroLoad />
      <TextBox></TextBox>
    </Wrapper>
  );
};

export default LoadingScreen;

const Wrapper = styled.div`
  height: 100%;
  width: 100vw;
  z-index: 3;
  background: white;
  text-align: center;
  position: absolute;
  ${flexbox({dir:'column'})}
`;

const AstroLoad = styled(AstroLoadSVG)`
  height: 100px;
  width: 100px;
  margin: 20px;
`;

const textAnimation = keyframes`
0%{
    content: "Lift Off In 3";
}
33%{
    content: "Lift Off In 2";
}
66%{
    content: "Lift Off In 1";
}


`;

const TextBox = styled.div`
  opacity: 1;
  font-family: 'Nova Square';
  font-size: 2rem;
  ::after {
    content: "Lift Off!";
    animation: ${textAnimation} 2s linear;
  }
`;
