import React from "react";
import styled from "styled-components";
import { whiteToTransparent } from "../style/gradients";

import ForText from "../assets/images/ParallaxMountains/ForTextM.png";
import Mountain1 from "../assets/images/ParallaxMountains/Mountain1M.gif";
import Mountain2 from "../assets/images/ParallaxMountains/Mountain2M.gif";
import Mountain3 from "../assets/images/ParallaxMountains/Mountain3M.gif";
import Mountain4 from "../assets/images/ParallaxMountains/Mountain4M.gif";
import Mountain5 from "../assets/images/ParallaxMountains/Mountain5M.gif";
import Mountain6 from "../assets/images/ParallaxMountains/Mountain6M.gif";

const ParallaxMountains = () => {
  
  return (
    <ParallaxWrapper id="parallaxmountains">
      <Logo image={ForText}>JORDAN'S</Logo>
      <Popup>PORTFOLIO</Popup>
      <PrlxLayerF image={Mountain6} />
      <PrlxLayerE image={Mountain5} />
      <PrlxLayerD image={Mountain4} />
      <PrlxLayerC image={Mountain3} />
      <PrlxLayerB image={Mountain2} />
      <PrlxLayerA image={Mountain1}>
        <Heightener>To. Outer. Space.</Heightener>
      </PrlxLayerA>
    </ParallaxWrapper>
  );
};

export default ParallaxMountains;

const ParallaxWrapper = styled.div`
  /* position: relative; */ /* NOTE! IF IN POSITION RELATIVE - PUSHES THE PAGE UP */
  height: 180vh;
  pointer-events: none;

  scroll-snap-stop: always;
  scroll-snap-align: center;
  
  @media (max-width: 767px) {
    scroll-snap-align: end;
    height: 140vh;
  }
`;

const Logo = styled.div`
  position: absolute;
  /* same as layers height to hide the logo */
  height: 105%;
  width: 100%;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10rem;
  font-family: "Nova Square", sans-serif;
  font-weight: 900;

  background: center / cover no-repeat url(${({ image }) => image});

  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;

  @media (max-width: 1920px) {
    font-size: 9rem;
  }

  @media (max-width: 1440px) {
    font-size: 8rem;
  }

  @media (max-width: 1080px) {
    font-size: 6.5rem;
  }

  @media (max-width: 768px) {
    font-size: 6rem;
  }

  @media (max-width: 480px) {
    font-size: 4rem;
  }
`;

const PrlxLayerF = styled.div`
  height: 105%;
  width: 100%;
  position: absolute;
  transform-origin: 0 0;
  transform: translateZ(-5.5px) scale(6.5);
  z-index: -7;

  background-color: darkblue;
  background: center/cover no-repeat url(${({ image }) => image});
`;

const Popup = styled(PrlxLayerF)`
  font-size: 5.5rem;
  transform: translateZ(-0.1px) scale(1.1);
  z-index: -3;
  background: none;
  text-align: center;
  line-height: 100vh;
  top: 9%;
  font-family: "Advent Pro", "Poiret One";
  font-weight: 600;

  @media (max-width: 1920px) {
    font-size: 5rem;
    top: 10%;
  }
  @media (max-width: 1440px) {
    font-size: 3.5rem;
    top: 10%;
  }
  @media (max-width: 1080px) {
    font-size: 3rem;
  }
  @media (max-width: 768px) {
    font-size: 4rem;
    top: 9%;
  }
  @media (max-width: 480px) {
    font-size: 3rem;
    top: 7%;
  }
`;

const PrlxLayerE = styled(PrlxLayerF)`
  background-image: url(${({ image }) => image});
  transform: translateZ(-4.5px) scale(5.5);
  z-index: -6;
`;

const PrlxLayerD = styled(PrlxLayerF)`
  background-image: url(${({ image }) => image});
  transform: translateZ(-3.5px) scale(4.5);
  z-index: -5;
`;

const PrlxLayerC = styled(PrlxLayerF)`
  background-image: url(${({ image }) => image});
  transform: translateZ(-2.5px) scale(3.5);
  z-index: -4;
`;

const PrlxLayerB = styled(PrlxLayerF)`
  background-image: url(${({ image }) => image});
  transform: translateZ(-1.5px) scale(2.5);
  z-index: -3;
`;

const PrlxLayerA = styled(PrlxLayerF)`
  background-image: url(${({ image }) => image});
  transform: translateZ(-0.5px) scale(1.5);
  z-index: -2;

  ::before {
    content: "";
    top: 85%;
    height: 15%;
    width: 100%;
    position: absolute;
    /* display: block; */
    z-index: 1;

    background: rgb(255, 255, 255);
    background: linear-gradient(to top, ${whiteToTransparent});
  }
`;

const Heightener = styled.div`
  /* this height hides the parallax slow moving layers */
  height: 40%;
  width: 100%;
  background: white;
  top: 100%;

  position: relative;
  display: flex;
  justify-content: center;

  padding-top: 3%;
  font-family: "Nova Square";
  font-size: 5rem;

  @media (max-width: 1440px) {
    font-size: 4.5rem;
  }
  @media (max-width: 768px) {
    font-size: 3.4rem;
  }
  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;
