import React, { useContext } from "react";
import styled from "styled-components";
import { GlobalContext } from "../state/GlobalContext";
import { FaDev } from "react-icons/fa";
import { AiFillExperiment, AiFillMail } from "react-icons/ai";
import * as c from "../style/colors";

const Nav = () => {
  const { visibleArea } = useContext(GlobalContext);
  return (
    <Wrapper>
      <Welcome visibleArea={visibleArea}>Welcome To</Welcome>
      <AboutLink href="#about" visibleArea={visibleArea}>
        <AboutIcon />
        About
      </AboutLink>
      <ProjectsLink href="#projects" visibleArea={visibleArea}>
        <ProjcetsIcon />
        Projects
      </ProjectsLink>
      <ContactLink href="#contact" visibleArea={visibleArea}>
        <ContactIcon />
        Contact
      </ContactLink>
    </Wrapper>
  );
};

export default Nav;

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  right: 25px;
  z-index: 1;
  transform: translateY(-50%);
  font-size: 2rem;
  overflow: hidden;
  text-align: right;

  font-family: "Nova Square", cursive;

  @media (max-width: 1440px) {
    font-size: 1.6rem;
  }
  @media (max-width: 768px) {
    transform: translateY(0);
    font-size: 1.6rem;
    right: 20px;
    top: 20px;
  }
  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

const Welcome = styled.div`
  display: none;

  @media (max-width: 768px) {
    transition: 0.3s ease-out;
    display: block;
    position: absolute;
    transform: ${({ visibleArea }) =>
      visibleArea === 0 ? "translateX(0)" : "translateX(120%)"};
    pointer-events: none;
    font-size: 1.3rem;
    opacity: 0.8;
  }
  @media (max-width: 480px) {
    font-size: 1.1rem;
  }
`;

const linksStyle = `
    display: flex;
    text-decoration: none;
    color: #D9DDDC;
    cursor: pointer;

    justify-content: center;
    align-items: center;
    transition: 0.3s ease-out;

    opacity: 0.6;

    &:hover {
    opacity: 1;
    transform: translateX(0);
    }

    &:active{
        color: white;
        transition: 0s;
    }
`;

const AboutLink = styled.a`
  ${linksStyle};
  transform: ${({ visibleArea }) =>
    visibleArea === 1 ? "translateX(10%)" : "translateX(68%)"};

  &:hover {
    transform: translateX(10%);
  }

  @media (max-width: 1440px) {
    transform: ${({ visibleArea }) =>
    visibleArea === 1 ? "translateX(10%)" : "translateX(63%)"};
  }
  
  @media (max-width: 768px) {
    position: absolute;
    transform: ${({ visibleArea }) =>
      visibleArea === 1 ? "translateX(20%)" : "translateX(120%)"};
    pointer-events: none;
  }
`;

const AboutIcon = styled(FaDev)`
  font-size: 2rem;
`;

const ProjectsLink = styled.a`
  ${linksStyle};
  transform: ${({ visibleArea }) =>
    visibleArea === 2 ? "" : "translateX(80%)"};

@media (max-width: 1440px) {
    transform: ${({ visibleArea }) =>
    visibleArea === 2 ? "translateX(0%)" : "translateX(75%)"};
  }
  @media (max-width: 768px) {
    position: absolute;
    transform: ${({ visibleArea }) =>
      visibleArea === 2 ? "translateX(-4%)" : "translateX(120%)"};
    pointer-events: none;
  }
`;

const ProjcetsIcon = styled(AiFillExperiment)`
  font-size: 2rem;
`;

const ContactLink = styled.a`
  ${linksStyle};

  transform: ${({ visibleArea }) =>
    visibleArea === 3 ? "" : "translateX(78%)"};

@media (max-width: 1440px) {
    transform: ${({ visibleArea }) =>
    visibleArea === 3 ? "translateX(0)" : "translateX(73%)"};
  }
  @media (max-width: 768px) {
    transform: ${({ visibleArea }) =>
      visibleArea === 3 ? "translateX(0)" : "translateX(120%)"};
    pointer-events: none;
  }
`;

const ContactIcon = styled(AiFillMail)`
  font-size: 2rem;
`;
