import React from "react";
import ReactDOM from "react-dom";
// import "./index.css";
import App from "./view/App.jsx";
import { GlobalProvider } from "./state/GlobalContext";

ReactDOM.render(
  <GlobalProvider>
    <App />
  </GlobalProvider>,
  document.getElementById("root")
);
