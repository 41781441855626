import { css, keyframes } from "styled-components";

export const subsAppear = (y) => keyframes`
0%{
    transform: translateY(100vh) scale(1.1);
    opacity: 0;
    color: transparent;
    text-shadow: 0px 0px 5px #000;
    filter: blur(15px)
}
100%{
    transform: translateY(${y}%) scale(1);
    opacity: 1;
    color: white;
    text-shadow: none;
    filter: none;
}
`;

export const subsAniController = (anim, length, delay) =>
  css`
    ${anim} ${length}s ${delay}s ease-in-out forwards
  `;

  //  \/ Switch Animation \/

export const switching = keyframes`
0%{
    opacity: 1;
    transform: translateY(0);
}
45%{
    opacity: 1;
    transform: translateY(0);
}
50%{
    opacity: 0;
    transform: translateY(-50%);
}
95%{
    opacity: 0;
    transform: translateY(50%);
}
100%{
    opacity: 1;
    transform: translateY(0);
}

`;

export const switchingController = delay => css`
  ${switching} 10s ${delay}s ease-in-out infinite
`;

// export const moveDesign = (x, y) => keyframes`
// 0%{
//     transform: translateX(${x}%) translateY(${y}%);
// }
// 50%{
//     transform: translateX(0) translateY(${y}%);
// }
// 100%{
//     transform: translate(0, 0);
// }
// `;

// export const moveDevCreate = (x, y) => keyframes`
// 0%{
//     transform: translate(${x}%, ${y}%);
// }
// 100%{
//     transform: translate(0,0);
// }
// `;

//   use example:
//      animation: ${({ sectionShowed }) =>
//      sectionShowed === 1 && subsAniController(moveDevCreate(0, 200), 0.3, 8)};
