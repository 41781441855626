import React, { useState, useEffect, useContext } from "react";
import ParallaxMoutains from "./ParallaxMountains";
import styled from "styled-components";
import Nav from "./Nav";
import visibility from "react-visibility-sensor";
import { GlobalContext } from "../state/GlobalContext";
import GlobalStyle from "../style/GlobalStyle";
import About from "../../src/view/sections/About";
import Projects from "../../src/view/sections/Projects/index.jsx";
import Contact from "../../src/view/sections/Contact";
import LoadingScreen from "./LoadingScreen"

const App = () => {
  const { visibleArea, setVisibleArea, beenShown, setBeenShown } = useContext(
    GlobalContext
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  const VisibilitySensor = visibility;
  // console.log('visibleArea', visibleArea);
  // console.log('beenShown', beenShown);

  const areaShown = areaNum => {
    console.log("boop")
    setVisibleArea(areaNum);
    if (areaNum in beenShown === false) {
      setBeenShown({
        ...beenShown,
        [areaNum]: areaNum // this way areaNum does not overwrite him self but rather uses the numbers as keys
      });
    }
  };

  return (
    <MainWrapper id="home">
      {isLoading && <LoadingScreen/>}
      <Nav />
      <Wrapper>
        {/* <ViewWrapper></ViewWrapper> */}
        <VisibilitySensor
          onChange={isVisible => isVisible && areaShown(0)}
          partialVisibility={true}
          offset={{ top: 10 }}
        >
          <ParallaxMoutains id="parallaxmoutains" />
        </VisibilitySensor>
      
        <VisibilitySensor
          onChange={isVisible => isVisible && areaShown(1)}
          offset={{ bottom: -400 }}
        >
          <About id="about" />
        </VisibilitySensor>
        <VisibilitySensor
          onChange={isVisible => isVisible && areaShown(2)}
          offset={{ bottom: -400 }}
        >
          <Projects id="projects" />
        </VisibilitySensor>
        <VisibilitySensor
          onChange={isVisible => isVisible && areaShown(3)}
          offset={{ bottom: -400 }}
        >
          <Contact id="contact" />
        </VisibilitySensor>
      </Wrapper>
      <GlobalStyle />
    </MainWrapper>
  );
};

export default App;

const MainWrapper = styled.div`
  max-width: 3440px;
  margin: auto;
  position: relative;
`;

const Wrapper = styled.div`
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scroll-snap-type: y proximity;
  scroll-snap-stop: always;
  
  perspective: 1px;
  background: black;
  perspective-origin: 0 0;
  position: relative;
  transform-style: preserve-3d;

  @media (max-width: 768px) {
    scroll-snap-type: y mandatory;
  }
`;
