import flyingAround from "../../../assets/videos/flyingAroundSmall.webm";
import React, { useContext } from "react";
import styled from "styled-components";
import { ReactComponent as AstroSitSVG } from "../../../assets/SVG/astronautSit.svg";
import SpaceBgG from "../../../assets/images/Space/smallgreen.png";
import * as g from "../../../style/gradients";

import { flexbox } from "../../../style/mixins";
import { GlobalContext } from "../../../state/GlobalContext";

import { GoMarkGithub } from "react-icons/go";
import { FaLinkedin } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";

import creditThumbnail from "../../../assets/Thumbnails/creditThumbnail.png";

import {
  subsAppear,
  subsAniController
} from "../../../style/animations/subsAnimations";

const Contact = () => {
  const { beenShown } = useContext(GlobalContext);

  const sectionShowed = beenShown["3"];

  const yarden = {
    name: "Yarden Shalom",
    pic: creditThumbnail,
    gitHub: "https://github.com/JordanSh",
    linkdin: "https://www.linkedin.com/in/yarden-shalom-a85a70197/",
    email: "yarden.shalom9@gmail.com",
    phone: "054-9100290"
  };

  const createContactInfo = ({ name, pic, gitHub, linkdin, email, phone }) => {
    return (
      <Maker sectionShowed={sectionShowed}>
        <DetailsBox>
          <NameBox href={linkdin} target="_blank">
            {name}
          </NameBox>
          <PicBox href={linkdin} target="_blank" image={pic} />
        </DetailsBox>

        <LinksBox>
          <WebBox>
            <LinkHref href={gitHub} target="_blank">
              <GoMarkGithub />
            </LinkHref>

            <LinkHref href={linkdin} target="_blank">
              <FaLinkedin />
            </LinkHref>

            <LinkHref href={`mailto:${email}`}>
              <AiOutlineMail />
            </LinkHref>
          </WebBox>

          <ContactBox>
            <FaWhatsapp /> <NumberSpan>{phone}</NumberSpan>
          </ContactBox>
          <ContactBox>
            <EmailSpan>{email}</EmailSpan>
          </ContactBox>
        </LinksBox>
      </Maker>
    );
  };

  return (
    <ContactDiv id="contact" image={SpaceBgG}>
      <AstroSit sectionShowed={sectionShowed} />
      <Container>{createContactInfo({ ...yarden })}</Container>

      <BackLinks>
        <BackVideo href="#parallaxmountains">
          <FlyingVideo playsinline autoPlay muted loop>
            <source src={flyingAround} type="video/webm" />
          </FlyingVideo>
        </BackVideo>
        <BackToEarth href="#parallaxmountains">Back. To. Earth.</BackToEarth>
      </BackLinks>
    </ContactDiv>
  );
};

export default Contact;

const ContactDiv = styled.div`
  ${flexbox()}
  height: 100vh;
  background: lightyellow;
  scroll-snap-align: center;
  scroll-snap-stop: always;
  position: relative;
  background: center / cover no-repeat url(${({ image }) => image});

  font-family: "Nova Square";

  ::before {
    content: "";
    top: -5%;
    height: 10%;
    width: 200vw;
    position: absolute;
    z-index: 1;
    background: linear-gradient(to top, ${g.blueFade});
    filter: blur(10px);
  }
`;

const AstroSit = styled(AstroSitSVG)`
  position: absolute;
  height: 10%;
  width: 10%;
  top: 12%;
  right: 19%;
  transition: 1.5s ease-out;
  transform: translateY(-20vh) scale(-1, 1);
  opacity: ${({ sectionShowed }) => (sectionShowed ? "1" : "0")};
  transform: ${({ sectionShowed }) =>
    sectionShowed && "translateY(0) scale(-1, 1)"};

  @media (max-width: 1080px) {
    top: 5%;
  }
  @media (max-width: 1024px) {
    top: 10%;
  }
  @media (max-width: 768px) {
    top: 7%;
    right: 2%;
    height: 15%;
    width: 15%;
  }
`;

const Container = styled.div`
  overflow: hidden;
  margin: auto;
  ${flexbox()};
  max-width: 1400px;
  width: 100%;
  height: 100vh;
  position: relative;
`;

const Maker = styled.div`
  flex-basis: 50%;
  text-align: center;
  height: 30%;
  width: 50%;
  max-width: 1200px;
  max-height: 300px;
  min-height: 200px;
  font-size: 2rem;

  ${flexbox()}
  position: relative;

  transform: translateY(-50%);

  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  color: white;
  padding: 10px;
  box-shadow: 1px 1px 2px #050c37;
  border-radius: 5px;

  opacity: 0;
  animation: ${({ sectionShowed }) =>
    sectionShowed && subsAniController(subsAppear(-50), 1, 0)};

  @media (max-width: 1080px) {
    transform: translateY(-50%);
    flex-basis: 50%;
    width: 50%;
    height: 30%;
  }

  @media (max-width: 768px) {
    flex-basis: 75%;
    width: 75%;
    height: 30%;
    max-width: 700px;
    flex-direction: column;
    justify-content: space-around;
  }
`;

const DetailsBox = styled.div`
  flex-basis: 50%;
  ${flexbox({ dir: "column" })}
  height: 100%;

  @media (max-width: 768px) {
    flex-basis: 30%;
    height: 30%;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
  }
`;

const NameBox = styled.a`
  flex-basis: 30%;
  ${flexbox()};
  text-shadow: 1px 1px 2px black;
  color: white;
  text-decoration: none;

  transition: 0.3s ease-in-out;
  &:hover {
    text-shadow: 0 0 2px white;
  }
`;
const PicBox = styled.a`
  ${flexbox()};
  width: 60%;
  height: 0;
  padding-bottom: 60%;
  border-radius: 50%;
  background: center / cover no-repeat url(${({ image }) => image});

  transition: 0.3s ease-in-out;
  &:hover {
    box-shadow: inset 0px 0px 20px white, 0px 0px 20px white;
  }

  @media (max-width: 768px) {
    width: 15%;
    padding-bottom: 15%;
  }
  @media (max-width: 500px) {
    width: 25%;
    padding-bottom: 25%;
  }
`;

const LinksBox = styled.div`
  flex-basis: 50%;
  ${flexbox({ dir: "column", jc: "space-between" })};
  height: 100%;

  @media (max-width: 768px) {
    flex-basis: 60%;
    width: 100%;
  }
`;

const WebBox = styled.div`
  ${flexbox({ jc: "space-between" })}
  flex-basis: 30%;
  width: 90%;

  @media (max-width: 768px) {
    width: 80%;
  }
`;

const LinkHref = styled.a`
  flex-basis: 30%;
  font-size: 3rem;
  opacity: 0.8;
  color: white;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const ContactBox = styled.div`
  ${flexbox()}
  flex-basis: 30%;
  width: 100%;
  text-align: left;
  font-size: 3.1rem;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const NumberSpan = styled.div`
  font-size: 1.5rem;
  padding-left: 18px;
  text-shadow: 1px 1px 2px black;
  width: 100%;
  flex-basis: 50%;
  ${flexbox()};

  @media (max-width: 768px) {
    font-size: 1.2rem;
    padding-left: 6px;
  }
`;

const EmailSpan = styled.div`
  font-size: 1.5rem;
  text-shadow: 1px 1px 2px black;
  width: 100%;
  flex-basis: 50%;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const BackLinks = styled.div`
  ${flexbox({ dir: "column" })};
  position: absolute;
  bottom: 10%;
`;

const BackToEarth = styled.a`
  font-size: 2rem;
  color: white;
  text-shadow: 0 0 2px black;

  transition: 0.3s ease-in-out;
  margin-top: 20px;
  text-decoration: none;

  cursor: pointer;

  &:hover {
    text-shadow: 0px 0px 5px white;
    text-decoration: underline;
  }
`;

const BackVideo = styled.a``;

const FlyingVideo = styled.video`
  border-radius: 50%;
  width: 175px;
  height: 175px;

  transition: 0.3s ease-in-out;
  box-shadow: 0px 0px 30px #050c37;

  &:hover {
    box-shadow: 0px 0px 50px white;
  }

  @media (max-width: 1440px) {
    width: 150px;
    height: 150px;
  }
  @media (max-width: 1080px) {
    width: 125px;
    height: 125px;
  }
`;
