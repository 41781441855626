import React, { useContext } from "react";
import styled from "styled-components";
import { flexbox } from "../../../style/mixins";
import { GlobalContext } from "../../../state/GlobalContext";

import {
  subsAppear,
  subsAniController
} from "../../../style/animations/subsAnimations";
import * as g from "../../../style/gradients";
import { ReactComponent as AstroWaveSVG } from "../../../assets/SVG/astronautWave.svg";
import SpaceBgP from "../../../assets/images/Space/smallPurple2.png";
import ProjectItem from "./ProjectItem";
import * as p from "./projectsList";

const Projects = () => {
  const { beenShown } = useContext(GlobalContext);

  const sectionShowed = beenShown["2"];

  const projectsList = [p.robogram, p.deadline, p.codeDown, p.colorBook, p.weatherProject];

  const ProjectItems = projectsList.map((project, index) => {
    return (
      <ProjectItem
        key={index}
        sectionShowed={sectionShowed}
        delayOffset={index / 2 - 0.3}
        PrjName={project.prjName}
        PrjDescription={project.prjDescription}
        PrjPicture={project.prjPicture}
        PrjSite={project.prjSite}
        PrjRepo={project.prjRepo}
        PrjLinkedin={project.Linkedin}
      />
    );
  });

  return (
    <ProjectsDiv id="projects" image={SpaceBgP}>
      <AstroWave sectionShowed={sectionShowed} />
      <Container>
      <Header sectionShowed={sectionShowed}>My Projects</Header>
        <ListContainer>{ProjectItems}</ListContainer>
      </Container>
    </ProjectsDiv>
  );
};

export default Projects;

const ProjectsDiv = styled.div`
  ${flexbox()};
  height: 100vh;
  scroll-snap-align: center;
  scroll-snap-stop: always;
  font-family: Montserrat;
  position: relative;
  background: center / cover no-repeat url(${({ image }) => image});
  background-color: linear-gradient(to top, ${g.blackToBlue});

  ::before {
    content: "";
    top: -5%;
    height: 10%;
    width: 100vw;
    position: absolute;
    z-index: 1;
    background: linear-gradient(to top, transparent, #050c37, transparent);
    filter: blur(10px);
  }

  @media (max-width: 768px) {
    padding-right: 10px;
  }
`;

const Container = styled.div`
  ${flexbox({dir:"column"})};
  height: 90%;
  overflow: hidden;
  margin: auto;
  max-width: 1400px;
  width: 100%;
  height: 100vh;
  position: relative;
`;

const Header = styled.div`
  flex-basis: 10%;
  font-size: 2rem;
  color: white;
  text-align: center;

  opacity: 0;
  animation: ${({ sectionShowed }) =>
    sectionShowed && subsAniController(subsAppear(0), 1, 0)};

@media (max-width: 768px) {
  flex-basis: 5%;
}
`;

const ListContainer = styled.div`
  flex-basis: 70%;
  height: 70%;
  overflow: hidden;
  ${flexbox({ dir: "row", fw: "wrap", jc: "flex-start" })};
  width: 75%;
  max-height: 800px;
  position: relative;
  overflow-y: auto;

  @media (min-width:1024px) {
    ::-webkit-scrollbar {
      width: 5px;
    }
  
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px white;
      border-radius: 5px;
    }
  
    ::-webkit-scrollbar-thumb {
      background-color: #363636;
      outline: 1px solid slategrey;
      border-radius: 5px;
    }
  }

  @media (max-width: 1024px) {
    max-height: 400px;
  }

  @media (max-width: 768px) {
    flex-wrap: nowrap;
    flex-basis: 75%;
    height: 75%;
    width: 100%;
    max-height: 600px;
    max-width: 400px;
    overflow-y: hidden;
    overflow-x: auto;

    scroll-snap-type: x mandatory;
  }
`;

const AstroWave = styled(AstroWaveSVG)`
  position: absolute;
  height: 9%;
  width: 9%;
  bottom: 46%;
  left: 1%;
  opacity: 0;
  animation: ${({ sectionShowed }) =>
    sectionShowed && subsAniController(subsAppear(0), 1, 0)};

  @media (max-width: 1440px) {
    bottom: 46%;
    left: 2%;
  }
  @media (max-width: 1024px) {
    height: 8%;
    width: 8%;
    left: 1%;
  }
  @media (max-width: 768px) {
    top: 6%;
    left: 3%;
    height: 10%;
    width: 10%;
  }
`;
