import React from "react";
import styled, { css, keyframes } from "styled-components";
import { flexbox } from "../../../style/mixins";
import {
  subsAppear,
  subsAniController,
  switching,
  switchingController
} from "../../../style/animations/subsAnimations";
import * as c from "../../../style/colors";

const Subs = ({ sectionShowed }) => {
  return (
    <SubsBox sectionShowed={sectionShowed}>
      <BlockDev>Full Stack Developer</BlockDev>
      <SwitchBox>
        <BlockDesign>Digital Designer </BlockDesign>
        <BlockAnimator> Animator</BlockAnimator>
      </SwitchBox>
      <SwitchBox>
        <BlockCreate>Creative Thinker</BlockCreate>
        <BlockAstronaut>AstroNOT</BlockAstronaut>
      </SwitchBox>
    </SubsBox>
  );
};

export default Subs;

const SubsBox = styled.div`
  ${flexbox({ dir: "column" })};
  position: relative;
  flex-basis: 20%;
  height: 90%;
  box-sizing: border-box;

  opacity: 0;
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(2px);

  animation: ${({ sectionShowed }) =>
    sectionShowed && subsAniController(subsAppear(0), 1, 4)};

  @media (max-width: 1024px) {
    flex-direction: row;
    width: 80%;
  }

  @media (max-width: 768px) {
    width: 100%;
    animation: ${({ sectionShowed }) =>
      sectionShowed && subsAniController(subsAppear(-30), 1, 4)};
  }
`;

const BlockDev = styled.div`
  flex-basis: 30%;
  width: 30%;
  ${flexbox()};
  text-align: center;
  color: white;
  text-shadow: 1px 1px 2px black;

  font-size: 3rem;
  line-height: 3.3rem;

  @media (max-width: 1440px) {
    font-size: 1.6rem;
    line-height: 1.8rem;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
    line-height: 1rem;
  }
`;

const SwitchBox = styled(BlockDev)`
  position: relative;
`;

const BlockDesign = styled(BlockDev)`
  opacity: 1;
  color: ${c.desireRed};
  animation: ${switchingController(10)};
`;

const BlockAnimator = styled(BlockDev)`
  opacity: 0;
  color: ${c.desireRed};
  position: absolute;
  animation: ${switchingController(15)};
`;

const BlockCreate = styled(BlockDev)`
  animation: ${switchingController(12.5)};
`;

const BlockAstronaut = styled(BlockDev)`
  opacity: 0;
  position: absolute;
  animation: ${switchingController(17.5)};
`;
