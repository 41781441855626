import React, { useState, createContext } from "react";

const GlobalContext = createContext();
const { Provider } = GlobalContext;

const GlobalProvider = ({ children }) => {
  const [visibleArea, setVisibleArea] = useState(0);
  const [beenShown, setBeenShown] = useState({});

  const state = { visibleArea, beenShown };

  const actions = { setVisibleArea, setBeenShown };

  return <Provider value={{ ...state, ...actions }}> {children} </Provider>;
};

export { GlobalContext, GlobalProvider };
