import codedownThumbnail from "../../../assets/Thumbnails/codedownThumbnail.png"
import robogramThumbnail from "../../../assets/Thumbnails/robogramThumbnail.png"
import weatherThumbnail from "../../../assets/Thumbnails/weatherThumbnail.png"
import deadlineThumbnail from "../../../assets/Thumbnails/deadlineThumbnail.png"
import colorBookThumbnail from "../../../assets/Thumbnails/colorBookThumbnail.png"

export const deadline = {
    prjName: "Deadline",
    prjDescription: "Task Prioritization Manager. Features Secured login and sign-up system using Bcrypt and JWT, Self-made RESTful API, Redux state management, Jest testing, search and sorting algorithems, export to CSV and much more",
    prjPicture: deadlineThumbnail,
    prjSite: "https://yardenshalom-deadline.netlify.com",
    prjRepo: "https://github.com/JordanSh/YardenShalom-Deadline",
    Linkedin: "https://linkedin.com/in/yarden-shalom-a85a70197/"
}

export const colorBook = {
    prjName: "React ColorBook",
    prjDescription: "Npm package component that will automatically convert your colors file into a visual form, so you can have an easier time picking the color you need",
    prjPicture: colorBookThumbnail,
    prjSite: "https://www.npmjs.com/package/react-colorbook",
    prjRepo: "https://github.com/JordanSh/react-colorbook",
    Linkedin: "https://linkedin.com/in/yarden-shalom-a85a70197/"
}

export const robogram = {
    prjName: "Robogram",
    prjDescription: "Full Stack Instagram clone, no humans are allowed, though. Features full CRUD users management and security systems, edit posts before uploading, personal hub, time of post tracking, and much more",
    prjPicture: robogramThumbnail,
    prjSite: "https://yardenshalom-robogram.netlify.com",
    prjRepo: "https://github.com/JordanSh/YardenShalom-Robogram",
    Linkedin: "https://linkedin.com/in/yarden-shalom-a85a70197/"
}

export const codeDown = {
    prjName: "Code Down",
    prjDescription: "Pixel-Art styled quiz learning game. UX/UI and most of the assets were designed, drawn and animated by me. Features several different game difficulties and questions with explanations for a fun learning experience",
    prjPicture: codedownThumbnail,
    prjSite: "https://yardenshalom-codedown.netlify.com/",
    prjRepo: "https://github.com/JordanSh/YardenShalom-CodeDown",
    Linkedin: "https://linkedin.com/in/yarden-shalom-a85a70197/"
}

export const weatherProject = {
    prjName: "Weather Project",
    prjDescription: "Full Stack Weather tracker based on AccuWeather's RESTful API. Features dark & light theme changer with fluid and minimal animations, full responsitivity for both desktop and mobile",
    prjPicture: weatherThumbnail,
    prjSite: "https://yardenshalom-weatherproject.netlify.com",
    prjRepo: "https://github.com/JordanSh/YardenShalom-WeatherProject",
    Linkedin: "https://linkedin.com/in/yarden-shalom-a85a70197/"
}