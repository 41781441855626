import React, { useContext } from "react";
import styled from "styled-components";
import { flexbox } from "../../../style/mixins";
import { GlobalContext } from "../../../state/GlobalContext";

import {
  subsAppear,
  subsAniController
} from "../../../style/animations/subsAnimations";
import * as g from "../../../style/gradients";
import { ReactComponent as AstroFishing } from "../../../assets/SVG/astronautFishing.svg";
import SpaceBg from "../../../assets/images/Space/blueSpace.png";
import Header from "./Header";
import Subs from "./Subs";
import Info from "./Info";

const About = () => {
  const { beenShown } = useContext(GlobalContext);
  
  const sectionShowed = beenShown["1"];

  return (
    <AboutDiv id="about" image={SpaceBg}>
      <Container>
        <Header sectionShowed={sectionShowed} />
        <AddOnsBox>
          <Astro sectionShowed={sectionShowed} />
          <Subs sectionShowed={sectionShowed} />
          <Info sectionShowed={sectionShowed} />
        </AddOnsBox>
      </Container>
    </AboutDiv>
  );
};

export default About;

const AboutDiv = styled.div`
  ${flexbox()}
  height: 100vh;
  scroll-snap-align: center;
  scroll-snap-stop: always;
  font-family: Montserrat;
  position: relative;
  background: center / cover no-repeat url(${({ image }) => image});
  background-color: linear-gradient(to top, ${g.blackToBlue});

  ::before {
    content: "";
    top: -35%;
    height: 35%;
    width: 100vw;
    position: absolute;
    z-index: 1;
    background: rgb(255, 255, 255);
    background: linear-gradient(to top,currentcolor 1%, transparent 75%);
    /* background: linear-gradient(to top, ${g.whiteToTransparent}); */
    @media (max-width: 768px){
      top: -10%;
    height: 10%;
    }
  }

  ::after {
    content: "";
    top: 0;
    height: 20%;
    width: 100vw;
    position: absolute;
    z-index: 1;
    background: rgb(255, 255, 255);
    background: linear-gradient(to bottom,currentcolor 1%, transparent 75%);
    /* background: linear-gradient(to top, ${g.whiteToTransparent}); */
    @media (max-width: 768px){
    height: 15%;
    }
  }
  

  @media (max-width: 768px) {
    padding: 0 2%;
  }
`;

const Container = styled.div`
  overflow: hidden;
  margin: auto;
  ${flexbox({ dir: "row", fw: "wrap" })};
  max-width: 1400px;
  height: 100vh;
  width: 100%;
  padding: 0 10%;
  position: relative;
`;

const AddOnsBox = styled.div`
  flex-basis: 100%;
  height: 70%;
  ${flexbox({ jc: "space-around" })};
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const Astro = styled(AstroFishing)`
  position: absolute;
  height: 15%;
  width: 15%;
  top: 18%;
  left: 20%;
  opacity: 0;
  animation: ${({ sectionShowed }) =>
    sectionShowed && subsAniController(subsAppear(0), 1, 4)};

  @media (max-width: 1024px) {
    top: 17%;
    left: 76%;
  }

  @media (max-width: 768px) {
    top: 14%;
    left: 82%;
  }
`;
