import React from "react";
import styled from "styled-components";
import { flexbox } from "../../../style/mixins";
import {
  subsAppear,
  subsAniController
} from "../../../style/animations/subsAnimations";
import * as g from "../../../style/gradients";

const Info = ({ sectionShowed }) => {
  return (
    <InfoBox sectionShowed={sectionShowed}>
      <Details>
        <p>
          <span>Welcome to my portfolio</span>,
        </p>
        <p>
          I'm a <span> Full Stack Web Developer</span>. I love working with
          <span> React</span> and generally anything at the Front-End is an
          actual passion of mine. That said, I do Back-End too.
        </p>
        <p>
          I've been Drawing, Designing, and Editing, my entire life. Personally,
          I look at Coding as an <span> Interactive Art form</span>.
        </p>
        <p>
          <span> UX/UI</span> and Client's Relations combined with
          <span> Creativity</span> are top priority for me.
        </p>
        <p>
          I'm a real <span> Self Learner</span>, Hard worker, and a Team player.
          'Can-do' Attitude and High attention to the little Details [they do make
          all the difference].
        </p>
        <p>
          <a href="#contact">Feel free to Contact me about anything</a>.
        </p>
      </Details>
    </InfoBox>
  );
};

export default Info;

const InfoBox = styled.div`
  ${flexbox({ ai: "flex-start" })};
  flex-basis: 75%;
  height: 90%;
  box-sizing: border-box;
  padding: 20px;
  opacity: 0;
  animation: ${({ sectionShowed }) =>
    sectionShowed && subsAniController(subsAppear(0), 1, 4.5)};

  @media (max-width: 1024px) {
    width: 80%;
  }

  @media (max-width: 768px) {
    flex-basis: 100%;
    width: 100%;
  }
`;

const Details = styled.div`
  text-align: left;
  overflow-y: auto;
  line-height: 2.1rem;
  font-size: 1.9rem;
  opacity: 1;
  box-sizing: border-box;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  color: white;

  ${g.textBlueOrange};
  background-clip: text;
  -webkit-background-clip: text;
  p {
    margin-bottom: 10px;

    @media (max-width: 768px) {
      margin-bottom: 5px;
    }
  }

  span {
    color: transparent;
  }

  a {
    color: transparent;
    transition: 0.3s ease-out;

    :hover {
    text-shadow: 0px 0px 1px white;
    }
  }

  @media (max-width: 1440px) {
    line-height: 1.7rem;
    font-size: 1.5rem;
  }

  @media (max-width: 1080px) {
    line-height: 1.3rem;
    font-size: 1.1rem;
  }

  @media (max-width: 1024px) {
    line-height: 1.4rem;
    font-size: 1.2rem;
  }

  @media (max-width: 768px) {
    line-height: 1.4rem;
    font-size: 1.1rem;
    width: 100%;
    animation: ${({ sectionShowed }) =>
      sectionShowed && subsAniController(subsAppear(-15), 1, 4.5)};
  }
  @media (max-width: 480px) {
    line-height: 0.9rem;
    font-size: 0.7rem;
  }
`;
